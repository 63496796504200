<template>
    <div class="overview">

   <!-- Header start -->
    <header class="header">
        <div class="header__container flex-space">
            <a href="/" >
                <h1>vim.is</h1>
            </a>
          
          
        </div>
    </header>
    <!-- Header end -->

	<div class="basic-1 cards-1">
	<div class="container">
		<div class="row">

        <div class="col-lg-12 ">
           <h2>Login</h2>
<br>
<div class="testimonial-item">
                    <span class="testimonial-item__name">License Key
                    </span>
                    
    <input type="text" placeholder="Enter License Key" name="ukey" v-model="key" required>
                      
                          <button type="submit" v-on:click="login();">Login</button>
                    <br><br>Need an account? <a href="https://pythoncourses.gumroad.com/l/GRjBCw/u1lm0i3">Signup</a>
                    </div>
                </div>

       
      </div>
      </div>
  </div>

  </div>
</template>

<script>
import router from '../router'

var axios = require('axios');

export default {
    components: {
    },
    name: 'HelloWorld',
    props: {
        msg: String
    },
    computed: {
        formattedElapsedTime() {
            const date = new Date(null);
            //date.setSeconds(this.elapsedTime / 1000);
            date.setMilliseconds(this.elapsedTime);
            const utc = date.toISOString();
            return utc.substr(utc.indexOf(".") - 2, 5) + 's';
        },
    },
    created: function() {
 

    },
    methods: {
       login: function() {
           console.log( this.key );
           axios.post("https://api.gumroad.com/v2/licenses/verify?product_permalink=GRjBCw&license_key=" + this.key)
             .then(response => {
                console.log(response.data)

                if (response.data['success'] === true) {
                    console.log('Success');
                    this.$cookie.set('key', this.key, 1);
                    router.replace("/exercise/0");
                    //86F5D94A-BF064DA1-B8078554-D48FAC37
                }
             })
             .catch(err => {
                console.log(err.response.data);
             })
           
       }
    },
    data: function() {
        return {
            elapsedTime: 1,
            totalTime: 1,
            averageTime: 1,
            timer: undefined,
            answer: "",
            score: 0,
            index: 0,
            data: [{},{}],
            title: '',
            exercise: [],
            key: '',
            api: [
            ]
        }
    }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.basic-1 {
 height: 100%;
 background: #fafafa;
}

@import '../assets/hero/css2.css';
@import '../assets/hero/normalize.css';
@import '../assets/hero/style.css';
@import '../assets/hero/responsive.css';


button {
  background-color: #218838;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

button:hover {
  opacity: 0.8;
}


form {border: 3px solid #f1f1f1;}

input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}


.btn.btn__default {
    color: black;
}

.above-heading {
    font-size: 12px;
    font-style: bold;
}

.hero-cta {
    color: white;
}

h2 {
    font-size: 4.2rem;
    line-height: 1.1;
    margin-bottom: 25px;
}
.above-heading {
    color: #5f4dee;
    font: 0.70.75rem/0.075rem "Open Sans", sans-serif;
    text-align: center;
}

h4 {
    margin: 41px 0 0;
}

ul {
    text-align:left;
}


#parent {
    width: 101%;
    height: 226px;
    display: block;
    position: relative;
}

#editor {
    position: absolute;
    /* Added */
    top: 1;
    right: 1;
    bottom: 1;
    left: 1;
    margin: 1 auto;
    border-radius: 9px;
}

.btn-solid-reg {
    cursor: pointer;
}

.score {
    font: 701 1rem/1.625rem "Open Sans", sans-serif;
    letter-spacing: 1.1px;
}

.divider {
    height: 2px;
    margin-top: 2.75rem;
    margin-bottom: 3rem;
    border: none;
    background-color: #ccd4df;
}

.box {
    display: block;
    max-width: 20rem;
    margin-right: auto;
    margin-bottom: 4rem;
    margin-left: auto;
    border: 2px solid #ccd3df;
    border-radius: 1.375rem;
    ;
    display: inline-block;
    margin: 11px;
    padding: 16px;
}

.testimonial-item__avatar {
    height: 6rem;
    width: 6rem;
    position: absolute;
    top: -6.4rem;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    border-radius: 50%;
    border: 0px;
}

:link { color: #0000EE; }
:visited { color: #551A8B; }

</style>
